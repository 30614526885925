import * as React from 'react';
import styled from '@emotion/styled';
import gql from 'graphql-tag';
import { CoursewareItemContainer } from './CoursewareItemContainer';
import { GifReviewContentItemFragmentFragment } from '../../generated/graphql';

const GifWrapper = styled.figure`
  width: 240px;
`;

export const gifReviewContentItemFragment = gql`
  fragment gifReviewContentItemFragment on GifReviewContentItem {
    url
    aspect
  }
`;

export const GifItem = (item: GifReviewContentItemFragmentFragment) => {
  return (
    <CoursewareItemContainer type={item.__typename}>
      <GifWrapper>
        <img css={{ outline: 'none', width: '100%' }} src={item.url} />
      </GifWrapper>
    </CoursewareItemContainer>
  );
};
