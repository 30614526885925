import * as React from 'react';
import styled from '@emotion/styled';
import { FloatingRightLabel } from '../RightLabel';

export const CoursewareItemContainer = (props: {
  type?: string;
  children: any;
}) => {
  const Outline = styled.div`
    &:hover {
      outline: 1px solid red;
    }
  `;
  return (
    <Outline>
      <FloatingRightLabel extra={props.type} />
      {props.children}
    </Outline>
  );
};
