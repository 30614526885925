import * as React from 'react';
import styled from '@emotion/styled';
import gql from 'graphql-tag';
import { DialogStatement, dialogStatementFragment } from './DialogStatement';
import { CoursewareItemContainer } from './CoursewareItemContainer';
import { DialogBaseSegmentFragmentFragment } from '../../generated/graphql';

const SegmentWrapper = styled.div`
  padding: 5px 6px 1px;
`;

export const dialogBaseSegmentFragment = gql`
  fragment dialogBaseSegmentFragment on DialogBaseSegment {
    statements {
      ...dialogStatementFragment
    }
  }
  ${dialogStatementFragment}
`;

export const DialogBaseSegment = (item: DialogBaseSegmentFragmentFragment) => {
  return (
    <CoursewareItemContainer type={item.__typename}>
      <SegmentWrapper>
        {item.statements.map((child, i) => (
          <DialogStatement key={i} {...child} />
        ))}
      </SegmentWrapper>
    </CoursewareItemContainer>
  );
};
