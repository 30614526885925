import * as React from 'react';
import styled from '@emotion/styled';
import gql from 'graphql-tag';
import { CoursewareItemContainer } from './CoursewareItemContainer';
import { DialogSpeakingQuestionFragmentFragment } from '../../generated/graphql';
import { DialogStatement, dialogStatementFragment } from './DialogStatement';

const QuestionWrapper = styled.div`
  padding-left: 6px;
`;
const AnswerWrapper = styled.div`
  padding-left: 8px;
`;
const ItemsWrapper = styled.div`
  padding: 20px 6px 1px 0;
`;
const CorrectIcon = styled.span`
  color: green;
  margin-right: 5px;
`;

export const dialogSpeakingQuestionFragment = gql`
  fragment dialogSpeakingQuestionFragment on DialogSpeakingQuestion {
    statements {
      ...dialogStatementFragment
    }
    content {
      text
    }
    maxAttempts
  }
  ${dialogStatementFragment}
`;

export const DialogSpeakingQuestion = (
  item: DialogSpeakingQuestionFragmentFragment,
) => {
  return (
    <CoursewareItemContainer type={item.__typename}>
      <QuestionWrapper>
        <ItemsWrapper>
          {item.statements.map((child, i) => (
            <DialogStatement key={i} {...child} />
          ))}
        </ItemsWrapper>
        <AnswerWrapper>
          <CorrectIcon>✔</CorrectIcon> {item.content.text}
        </AnswerWrapper>
      </QuestionWrapper>
    </CoursewareItemContainer>
  );
};
