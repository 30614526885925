import * as React from 'react';
import styled from '@emotion/styled';
import gql from 'graphql-tag';
import { CoursewareItemContainer } from './CoursewareItemContainer';
import { DialogMultipleChoiceTextQuestionFragmentFragment } from '../../generated/graphql';
import { DialogStatement, dialogStatementFragment } from './DialogStatement';

const QuestionWrapper = styled.div``;
const ItemsWrapper = styled.div`
  padding: 5px 6px 1px;
`;
const Choices = styled.div`
  display: flex;
  padding-bottom: 5px;
`;

interface ChoiceProps {
  isCorrect: boolean;
}
const Choice = styled.div<ChoiceProps>(
  ({ isCorrect }) => `
    border-style: solid; 
    border-color: ${isCorrect ? 'green' : '#EEE'};
    border-width: 1px;
    border-radius: 10px;
    padding: 5px;
    margin: 0 5px;
    font-size: 12px;
  `,
);
const CorrectIcon = styled.span`
  color: green;
  margin-right: 5px;
`;

export const dialogMultipleChoiceTextQuestionFragment = gql`
  fragment dialogMultipleChoiceTextQuestionFragment on DialogMultipleChoiceTextQuestion {
    statements {
      ...dialogStatementFragment
    }
    choices {
      text
    }
    correctChoiceIndices
    maxAttempts
  }
  ${dialogStatementFragment}
`;

export const DialogMultipleChoiceTextQuestion = (
  item: DialogMultipleChoiceTextQuestionFragmentFragment,
) => {
  return (
    <CoursewareItemContainer type={item.__typename}>
      <QuestionWrapper>
        <ItemsWrapper>
          {item.statements.map((child, i) => (
            <DialogStatement key={i} {...child} />
          ))}
        </ItemsWrapper>
        <Choices>
          {item.choices.map((choice, i) => (
            <Choice key={i} isCorrect={item.correctChoiceIndices.includes(i)}>
              {item.correctChoiceIndices.includes(i) && (
                <CorrectIcon>✔</CorrectIcon>
              )}
              {choice.text}
            </Choice>
          ))}
        </Choices>
      </QuestionWrapper>
    </CoursewareItemContainer>
  );
};
