import React, { FC, useState } from 'react';
import gql from 'graphql-tag';
import {
  Avatar,
  Box,
  Chip,
  Dialog,
  DialogTitle,
  Typography,
  makeStyles,
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import { VocabChipWithDialogItemFragment } from '../../generated/graphql';

export const vocabChipWithDialogItemFragment = gql`
  fragment vocabChipWithDialogItem on CoursewareVocab {
    id
    word
    translation
    definition {
      text
      localizedText
    }
    imageUrl
    audioUrl
    examples {
      text
      localizedText
    }
    phonetics
  }
`;

interface VocabChipWithDialogProps {
  item: VocabChipWithDialogItemFragment;
}

const useStyle = makeStyles(theme => ({
  image: {
    maxWidth: '300px',
  },
  audio: {
    outline: 'none',
    marginTop: theme.spacing(2),
    width: '100%',
  },
  example: {
    marginBottom: theme.spacing(2),
    fontSize: '12px',
  },
  translation: {
    color: '#AAA',
  },
}));

export const VocabChipWithDialog: FC<VocabChipWithDialogProps> = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);
  const styles = useStyle();
  return (
    <>
      <Chip
        key={item.id}
        label={item.word}
        avatar={item.imageUrl ? <Avatar src={item.imageUrl} /> : undefined}
        deleteIcon={<DoneIcon />}
        onDelete={() => setIsOpen(true)}
        onClick={() => setIsOpen(true)}
        clickable
      />
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>
          {item.word}{' '}
          {item.translation && item.translation !== item.word && (
            <span className={styles.translation}>{item.translation}</span>
          )}
        </DialogTitle>
        <Box p={2}>
          {item.phonetics && (
            <Typography variant="overline">{item.phonetics}</Typography>
          )}
          <Typography variant="body1">{item.definition.text}</Typography>
          {item.definition.localizedText &&
            item.definition.localizedText !== item.definition.text && (
              <Typography variant="body2">
                {item.definition.localizedText}
              </Typography>
            )}
          {item.audioUrl && (
            <audio
              className={styles.audio}
              controls
              src={item.audioUrl}
            ></audio>
          )}
          {item.examples && (
            <Box paddingTop={2}>
              <Typography variant="caption">Examples</Typography>
              {item.examples.map(example => (
                <div key={example.text} className={styles.example}>
                  <Typography variant="body1">{example.text}</Typography>
                  {example.localizedText &&
                    example.localizedText !== example.text && (
                      <Typography variant="body2">
                        {example.localizedText}
                      </Typography>
                    )}
                </div>
              ))}
            </Box>
          )}
          {item.imageUrl && (
            <Box paddingTop={2}>
              <img src={item.imageUrl} className={styles.image} />
            </Box>
          )}
        </Box>
      </Dialog>
    </>
  );
};
