import * as React from 'react';
import gql from 'graphql-tag';
import styled from '@emotion/styled';
import { AudioMessageItem, audioMessageItemFragment } from './AudioMessageItem';
import { VideoMessageItem, videoMessageItemFragment } from './VideoMessageItem';
import { ImageMessageItem, imageMessageItemFragment } from './ImageMessageItem';
import { TextMessageItem, textMessageItemFragment } from './TextMessageItem';
import { CardMessageItem, cardMessageItemFragment } from './CardMessageItem';
import {
  CardCarouselMessageItem,
  cardCarouselMessageItemFragment,
} from './CardCarouselMessageItem';
import {
  AudioCarouselMessageItem,
  audioCarouselMessageItemFragment,
} from './AudioCarouselMessageItem';
import { gifMessageItemFragment, GifMessageItem } from './GifMessageItem';
import { webLinkMessageItemFragment } from './WebLinkMessageItem';
import { MessageItemFragmentFragment } from '../../generated/graphql';

export const messageItemFragment = gql`
  fragment messageItemFragment on MessageItem {
    ...textMessageItemFragment
    ...gifMessageItemFragment
    ...webLinkMessageItemFragment
    ...cardMessageItemFragment
    ...videoMessageItemFragment
    ...audioMessageItemFragment
    ...imageMessageItemFragment
    ...audioCarouselMessageItemFragment
    ...cardCarouselMessageItemFragment
  }

  ${textMessageItemFragment}
  ${gifMessageItemFragment}
  ${webLinkMessageItemFragment}
  ${cardMessageItemFragment}
  ${videoMessageItemFragment}
  ${audioMessageItemFragment}
  ${imageMessageItemFragment}
  ${audioCarouselMessageItemFragment}
  ${cardCarouselMessageItemFragment}
`;

const MessageItemWrapper = styled.div`
  background: #f5f5f5;
  border-radius: 5px;
  padding: 5px;
  margin: 10px 0;
`;

export const MessageItem = (item: MessageItemFragmentFragment) => {
  const getInnerItem = () => {
    switch (item.__typename) {
      case 'AudioMessageItem':
        return <AudioMessageItem {...item} />;
      case 'VideoMessageItem':
        return <VideoMessageItem {...item} />;
      case 'CardMessageItem':
        return <CardMessageItem {...item} />;
      case 'CardCarouselMessageItem':
        return <CardCarouselMessageItem {...item} />;
      case 'ImageMessageItem':
        return <ImageMessageItem {...item} />;
      case 'GifMessageItem':
        return <GifMessageItem {...item} />;
      case 'TextMessageItem':
        return <TextMessageItem {...item} />;
      case 'AudioCarouselMessageItem':
        return <AudioCarouselMessageItem {...item} />;
      default:
        return <p>Unimplemented item type: {item.__typename}</p>;
    }
  };

  return <MessageItemWrapper>{getInnerItem()}</MessageItemWrapper>;
};
