import React, { FC, ReactNode, useState } from 'react';
import {
  FormGroup,
  FormControlLabel,
  Switch,
  FormControl,
  Select,
  MenuItem,
  Box,
  makeStyles,
} from '@material-ui/core';
import * as persistentStorage from '../persistentStorage';

const defaultSecondaryLanguage = persistentStorage.getSecondaryLanguage();
const defaultLocalizeContent = persistentStorage.getLocalizeContent();

interface ILanguageToggleContextContainerProps {
  children: ReactNode;
}

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  controls: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export const LocalizeContext = React.createContext(defaultLocalizeContent);

export const LanguageToggleContextContainer: FC<ILanguageToggleContextContainerProps> = ({
  children,
}) => {
  const [secondaryLanguage, setSecondaryLanguage] = useState(
    defaultSecondaryLanguage,
  );
  const [localizeContent, setLocalizeContent] = useState(
    defaultLocalizeContent,
  );
  const classes = useStyles();

  return (
    <div>
      <Box className={classes.controls}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                checked={localizeContent}
                onChange={evt => {
                  setLocalizeContent(evt.target.checked);
                  persistentStorage.setLocalizeContent(evt.target.checked);
                }}
              />
            }
            label="Localize"
          />
          <FormControl className={classes.formControl}>
            <Select
              value={secondaryLanguage}
              onChange={evt => {
                setSecondaryLanguage(
                  evt.target.value as persistentStorage.SecondaryLanguage,
                );
                persistentStorage.setSecondaryLanguage(
                  evt.target.value as persistentStorage.SecondaryLanguage,
                );
                // need to force a reload of the graphQL request. This is hacky way to make that happen
                window.location.reload();
              }}
            >
              {persistentStorage.SECONDARY_LANGUAGES.map(lang => (
                <MenuItem key={lang} value={lang}>
                  {lang}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FormGroup>
      </Box>
      <LocalizeContext.Provider value={localizeContent}>
        {children}
      </LocalizeContext.Provider>
    </div>
  );
};
