import * as React from 'react';
import gql from 'graphql-tag';
import { AudioItem, audioReviewContentItemFragment } from './Audio';
import { VideoItem, videoReviewContentItemFragment } from './Video';
import { ImageItem, imageReviewContentItemFragment } from './Image';
import { TextItem, textReviewContentItemFragment } from './Text';
import { CardItem, cardReviewContentItemFragment } from './Card';
import {
  CardCarouselItem,
  cardCarouselReviewContentItemFragment,
} from './CardCarousel';
import {
  AudioCarouselItem,
  audioCarouselReviewContentItemFragment,
} from './AudioCarousel';
import { gifReviewContentItemFragment, GifItem } from './Gif';
import { webLinkReviewContentItemFragment, WebLinkItem } from './WebLink';
import { ReviewContentItemFragmentFragment } from '../../generated/graphql';

export const reviewContentItemFragment = gql`
  fragment reviewContentItemFragment on ReviewContentItem {
    ...textReviewContentItemFragment
    ...gifReviewContentItemFragment
    ...webLinkReviewContentItemFragment
    ...cardReviewContentItemFragment
    ...videoReviewContentItemFragment
    ...audioReviewContentItemFragment
    ...imageReviewContentItemFragment
    ...audioCarouselReviewContentItemFragment
    ...cardCarouselReviewContentItemFragment
  }

  ${textReviewContentItemFragment}
  ${gifReviewContentItemFragment}
  ${webLinkReviewContentItemFragment}
  ${cardReviewContentItemFragment}
  ${videoReviewContentItemFragment}
  ${audioReviewContentItemFragment}
  ${imageReviewContentItemFragment}
  ${audioCarouselReviewContentItemFragment}
  ${cardCarouselReviewContentItemFragment}
`;

export const CoursewareItem = (item: ReviewContentItemFragmentFragment) => {
  switch (item.__typename) {
    case 'AudioReviewContentItem':
      return <AudioItem {...item} />;
    case 'VideoReviewContentItem':
      return <VideoItem {...item} />;
    case 'CardReviewContentItem':
      return <CardItem {...item} />;
    case 'CardCarouselReviewContentItem':
      return <CardCarouselItem {...item} />;
    case 'ImageReviewContentItem':
      return <ImageItem {...item} />;
    case 'TextReviewContentItem':
      return <TextItem {...item} />;
    case 'AudioCarouselReviewContentItem':
      return <AudioCarouselItem {...item} />;
    case 'WebLinkReviewContentItem':
      return <WebLinkItem {...item} />;
    case 'GifReviewContentItem':
      return <GifItem {...item} />;
    default:
      return (
        <p>
          Unimplemented item type:{' '}
          {(item as ReviewContentItemFragmentFragment).__typename}
        </p>
      );
  }
};
