import * as React from 'react';
import styled from '@emotion/styled';

export const LeftHighlightBorderContainer = (props: {
  color: string;
  pad?: boolean;
  children: any;
}) => {
  const BorderContainer = styled.div`
    position: relative;
    box-shadow: -3px 0px 0px 0px ${props.color};
    padding-left: 6px;
    ${props.pad ? ' padding-top: 40px' : ''};
  `;
  return <BorderContainer>{props.children}</BorderContainer>;
};
