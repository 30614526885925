import * as React from 'react';
import gql from 'graphql-tag';
import styled from '@emotion/styled';
import { LeftHighlightBorderContainer } from '../LeftHighlightBorderContainer';
import { FloatingLeftLabel } from '../FloatingLeftLabel';
import { textReviewContentItemFragment } from './Text';
import { gifReviewContentItemFragment } from './Gif';
import { webLinkReviewContentItemFragment } from './WebLink';
import { cardReviewContentItemFragment } from './Card';
import { videoReviewContentItemFragment } from './Video';
import { audioReviewContentItemFragment } from './Audio';
import { imageReviewContentItemFragment } from './Image';
import { audioCarouselReviewContentItemFragment } from './AudioCarousel';
import { cardCarouselReviewContentItemFragment } from './CardCarousel';
import { reviewExampleFragment, Example } from './Example';
import { CoursewareItem } from './CoursewareItem';
import {
  ReviewTopicFragmentFragment,
  ReviewContentItemFragmentFragment,
} from '../../generated/graphql';
import { LocalizedText } from '../LocalizedText';

export const reviewTopicFragment = gql`
  fragment reviewTopicFragment on ReviewTopic {
    topicTitle {
      text
      localizedText
    }
    body {
      ...reviewExampleFragment

      ...textReviewContentItemFragment
      ...gifReviewContentItemFragment
      ...webLinkReviewContentItemFragment
      ...cardReviewContentItemFragment
      ...videoReviewContentItemFragment
      ...audioReviewContentItemFragment
      ...imageReviewContentItemFragment
      ...audioCarouselReviewContentItemFragment
      ...cardCarouselReviewContentItemFragment
    }
  }

  ${textReviewContentItemFragment}
  ${gifReviewContentItemFragment}
  ${webLinkReviewContentItemFragment}
  ${cardReviewContentItemFragment}
  ${videoReviewContentItemFragment}
  ${audioReviewContentItemFragment}
  ${imageReviewContentItemFragment}
  ${audioCarouselReviewContentItemFragment}
  ${cardCarouselReviewContentItemFragment}
  ${reviewExampleFragment}
`;

const TopicContainer = styled.div`
  position: relative;
`;

const TopicHeadingTitle = styled.h2`
  margin 8px 0 8px 0;
`;

export const Topic = (topic: ReviewTopicFragmentFragment) => {
  const text = (topic.topicTitle && topic.topicTitle.text) || undefined;

  return (
    <TopicContainer>
      <LeftHighlightBorderContainer color="blue" pad>
        <FloatingLeftLabel title="Topic" extra={text} />
        {topic.topicTitle && (
          <TopicHeadingTitle>
            <LocalizedText item={topic.topicTitle} />
          </TopicHeadingTitle>
        )}
        {topic.body.map((item, i) => {
          return item.__typename === 'ReviewExample' ? (
            <Example {...item} key={i} />
          ) : (
            <CoursewareItem
              {...(item as ReviewContentItemFragmentFragment)}
              key={i}
            />
          );
        })}
      </LeftHighlightBorderContainer>
    </TopicContainer>
  );
};
