import * as React from 'react';
import gql from 'graphql-tag';
import { LeftHighlightBorderContainer } from '../LeftHighlightBorderContainer';
import { FloatingLeftLabel } from '../FloatingLeftLabel';
import { reviewContentItemFragment, CoursewareItem } from './CoursewareItem';
import { TextItem } from './Text';
import { ReviewExampleFragmentFragment } from '../../generated/graphql';

export const reviewExampleFragment = gql`
  fragment reviewExampleFragment on ReviewExample {
    exampleTitle {
      text
      localizedText
    }
    items {
      ...reviewContentItemFragment
    }
  }
  ${reviewContentItemFragment}
`;

export const Example = (item: ReviewExampleFragmentFragment) => (
  <LeftHighlightBorderContainer color="green">
    <FloatingLeftLabel extra={item.__typename} />
    {item.exampleTitle && (
      <TextItem {...item.exampleTitle} __typename="TextReviewContentItem" />
    )}
    {item.items.map((child, i) => (
      <CoursewareItem key={i} {...child} />
    ))}
  </LeftHighlightBorderContainer>
);
