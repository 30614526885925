import * as React from 'react';
import gql from 'graphql-tag';
import styled from '@emotion/styled';
import queryString from 'query-string';
import { makeStyles } from '@material-ui/core';
import { MessageItem, messageItemFragment } from './MessageItem';
import { DialogStatementFragmentFragment } from '../../generated/graphql';

export const dialogStatementFragment = gql`
  fragment dialogStatementFragment on DialogStatement {
    identifier
    item {
      ...messageItemFragment
    }
    pauseChoice {
      text
    }
  }

  ${messageItemFragment}
`;

const PauseWrapper = styled.div`
  padding: 10px;
  color: #999;
  border: 1px solid #999;
  border-radius: 5px;
  margin: 10px 0;
  display: inline-block;
`;

const useStyles = makeStyles({
  highlighted: {
    border: '3px solid purple',
    padding: '5px',
    position: 'relative',
    '&::after': {
      content: '"progress →"',
      position: 'absolute',
      right: '100%',
      bottom: '30px',
      width: '150px',
      textAlign: 'right',
      paddingRight: '40px',
      color: 'purple',
      fontWeight: 'bold',
    },
  },
});

export const DialogStatement = (item: DialogStatementFragmentFragment) => {
  const styles = useStyles();
  const hashParams = queryString.parse(window.location.hash);
  const isHighlighted = hashParams?.highlightStatement === item.identifier;
  return (
    <div className={isHighlighted ? styles.highlighted : undefined}>
      <MessageItem {...item.item} />
      {item.pauseChoice && <PauseWrapper>{item.pauseChoice.text}</PauseWrapper>}
    </div>
  );
};
