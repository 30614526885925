import * as React from 'react';
import { Link } from 'gatsby';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Box, Divider } from '@material-ui/core';
import flatten from 'lodash.flatten';

import Container from '../common/Container';
import { CoursewareLessonQuery, LessonNavQuery } from '../../generated/graphql';
import { normalizeId } from '../../normalizeId';
import { GraphQLErrorDisplay } from '../GraphQLErrorDisplay';
import { exists } from '../../util';

const coursewareLessonQuery = gql`
  query CoursewareLesson($id: ID!) {
    node(id: $id) {
      ... on CoursewareLesson {
        id
        title
        numberInCourse
        course {
          id
          title
          cefrLevel
        }
        sections {
          id
          title
        }
      }
    }
  }
`;

const lessonNavQuery = gql`
  query LessonNav {
    courses {
      id
      lessons {
        id
      }
    }
  }
`;

const renderLessonNav = (lessonId: string) => {
  const { loading, error, data } = useQuery<LessonNavQuery>(lessonNavQuery);
  if (error) return <GraphQLErrorDisplay error={error} />;
  if (loading || !data?.courses) return null;
  const allLessonIds = flatten(
    data?.courses.map(course => course?.lessons.map(lesson => lesson.id)),
  ).filter(exists);
  const lessonIndex = allLessonIds.indexOf(lessonId);
  if (lessonIndex === -1) return null;
  const nextLessonLink =
    lessonIndex < allLessonIds.length - 1 ? (
      <Link to={`/courseware/lesson/${allLessonIds[lessonIndex + 1]}`}>
        next →
      </Link>
    ) : null;
  const prevLessonLink =
    lessonIndex > 0 ? (
      <Box marginRight={2} display="inline-block">
        <Link to={`/courseware/lesson/${allLessonIds[lessonIndex - 1]}`}>
          ← previous
        </Link>
      </Box>
    ) : null;
  return (
    <Box marginTop={2} fontSize="12px">
      {prevLessonLink}
      {nextLessonLink}
    </Box>
  );
};

interface CoursewareLessonPageProps {
  id: string;
}

const CoursewareLessonPage = (props: CoursewareLessonPageProps) => {
  const id = normalizeId(props.id, 'CoursewareLesson');

  const { loading, error, data } = useQuery<CoursewareLessonQuery>(
    coursewareLessonQuery,
    { variables: { id } },
  );

  const lessonNav = renderLessonNav(id);

  if (loading) return 'Loading...';
  if (error) return <GraphQLErrorDisplay error={error} />;

  if (data?.node?.__typename !== 'CoursewareLesson') return 'Not found :(';
  const lesson = data.node;

  return (
    <Container>
      <h1>{lesson.title}</h1>
      <Box marginY={2}>
        <Link to="/courseware">{lesson.course.title}</Link>
        <br />
        Lesson {lesson.numberInCourse}
        <br />
        {lessonNav}
      </Box>
      <Divider />
      <Box marginY={2}>
        {lesson.sections.map(
          section =>
            section && (
              <div key={section.id}>
                <Link to={`/courseware/section/${section.id}`}>
                  {section.title}
                </Link>
              </div>
            ),
        )}
      </Box>
    </Container>
  );
};

export default CoursewareLessonPage;
