import * as React from 'react';
import styled from '@emotion/styled';
import gql from 'graphql-tag';
import { CoursewareItemContainer } from './CoursewareItemContainer';
import { ImageMessageItemFragmentFragment } from '../../generated/graphql';

const ImageWrapper = styled.div`
  width: 240px;
`;

export const imageMessageItemFragment = gql`
  fragment imageMessageItemFragment on ImageMessageItem {
    url
    aspect
  }
`;

export const ImageMessageItem = (item: ImageMessageItemFragmentFragment) => {
  return (
    <CoursewareItemContainer type={item.__typename}>
      <ImageWrapper>
        <img css={{ outline: 'none', width: '100%' }} src={item.url} />
      </ImageWrapper>
    </CoursewareItemContainer>
  );
};
