import * as React from 'react';
import styled from '@emotion/styled';
import gql from 'graphql-tag';
import { DialogStatement, dialogStatementFragment } from './DialogStatement';
import { CoursewareItemContainer } from './CoursewareItemContainer';
import {
  DialogMultipleChoiceTextQuestion,
  dialogMultipleChoiceTextQuestionFragment,
} from './DialogMultipleChoiceTextQuestion';
import {
  DialogSpeakingQuestion,
  dialogSpeakingQuestionFragment,
} from './DialogSpeakingQuestion';
import { DialogQuestionSegmentFragmentFragment } from '../../generated/graphql';

const SegmentWrapper = styled.div`
  padding: 5px 6px 1px;
`;

export const dialogQuestionSegmentFragment = gql`
  fragment dialogQuestionSegmentFragment on DialogQuestionSegment {
    statements {
      ...dialogStatementFragment
    }
    questions {
      ...dialogMultipleChoiceTextQuestionFragment
      ...dialogSpeakingQuestionFragment
    }
  }

  ${dialogMultipleChoiceTextQuestionFragment}
  ${dialogSpeakingQuestionFragment}
  ${dialogStatementFragment}
`;

export const DialogQuestionSegment = (
  item: DialogQuestionSegmentFragmentFragment,
) => {
  return (
    <CoursewareItemContainer type={item.__typename}>
      <SegmentWrapper>
        {item.statements.map((child, i) => (
          <DialogStatement key={i} {...child} />
        ))}
        {item.questions.map((child, i) => {
          if (child.__typename === 'DialogMultipleChoiceTextQuestion')
            return <DialogMultipleChoiceTextQuestion {...child} key={i} />;
          if (child.__typename === 'DialogSpeakingQuestion')
            return <DialogSpeakingQuestion {...child} key={i} />;
          return null;
        })}
      </SegmentWrapper>
    </CoursewareItemContainer>
  );
};
