import * as React from 'react';
import styled from '@emotion/styled';
import gql from 'graphql-tag';
import { CoursewareItemContainer } from './CoursewareItemContainer';
import { ImageReviewContentItemFragmentFragment } from '../../generated/graphql';

const ImageWrapper = styled.figure`
  width: 240px;
`;

export const imageReviewContentItemFragment = gql`
  fragment imageReviewContentItemFragment on ImageReviewContentItem {
    url
    aspect
  }
`;

export const ImageItem = (item: ImageReviewContentItemFragmentFragment) => {
  return (
    <CoursewareItemContainer type={item.__typename}>
      <ImageWrapper>
        <img css={{ outline: 'none', width: '100%' }} src={item.url} />
      </ImageWrapper>
    </CoursewareItemContainer>
  );
};
