import * as React from 'react';
import styled from '@emotion/styled';
import { Markdown } from './Markdown';

export const FloatingLeftLabel: React.FC<any> = (props: {
  title?: string;
  extra?: string;
  color?: string;
}) => {
  const LeftLabel = styled.div`
    position: absolute;
    right: 110%;
    top: 0;
    min-width: 160px;
    opacity: 0.2;

    div:hover > & {
      opacity: 1;
    }
  `;

  const Title = styled.h3`
    color: rgba(0, 0, 0, 0.87);
    font-size: 1.1125rem;
    margin: 0;
  `;
  const Extra = styled.div`
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.75rem;
    font-weight: 400;
    margin: 4px 0 0 0;
  `;

  return (
    <LeftLabel>
      <Title>
        <Markdown text={props.title} />
      </Title>
      <Extra>
        <Markdown text={props.extra} />
      </Extra>
    </LeftLabel>
  );
};
