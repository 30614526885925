import * as React from 'react';
import styled from '@emotion/styled';
import gql from 'graphql-tag';
import { AudioItem, audioReviewContentItemFragment } from './Audio';
import { CoursewareItemContainer } from './CoursewareItemContainer';
import { AudioCarouselReviewContentItemFragmentFragment } from '../../generated/graphql';

const CarouselWrapper = styled.figure`
  padding: 20px 6px 1px;
`;

export const audioCarouselReviewContentItemFragment = gql`
  fragment audioCarouselReviewContentItemFragment on AudioCarouselReviewContentItem {
    items {
      ...audioReviewContentItemFragment
    }
  }
  ${audioReviewContentItemFragment}
`;

export const AudioCarouselItem = (
  item: AudioCarouselReviewContentItemFragmentFragment,
) => {
  return (
    <CoursewareItemContainer type={item.__typename}>
      <CarouselWrapper>
        {item.items.map((audio, i) => (
          <AudioItem key={i} {...audio} />
        ))}
      </CarouselWrapper>
    </CoursewareItemContainer>
  );
};
