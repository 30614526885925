import * as React from 'react';

import { Router } from '@reach/router';
import Page from '../components/common/Page';
import Container from '../components/common/Container';
import IndexLayout from '../components/common/Layout';
import CoursewareIndexPage from '../components/subpages/CoursewareIndexPage';
import CoursewareLessonPage from '../components/subpages/CoursewareLessonPage';
import CoursewareSectionPage from '../components/subpages/CoursewareSectionPage';
import { Route } from '../components/Route';

const CoursewarePage = () => {
  return (
    <IndexLayout>
      <Page>
        <Container>
          <Router>
            <Route path="/courseware" component={CoursewareIndexPage} />
            <Route
              path="/courseware/lesson/:id"
              component={CoursewareLessonPage}
            />
            <Route
              path="/courseware/section/:id"
              component={CoursewareSectionPage}
            />
          </Router>
        </Container>
      </Page>
    </IndexLayout>
  );
};

export default CoursewarePage;
