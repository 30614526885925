import * as React from 'react';
import styled from '@emotion/styled';
import gql from 'graphql-tag';
import { CoursewareItemContainer } from './CoursewareItemContainer';
import { TextReviewContentItemFragmentFragment } from '../../generated/graphql';
import { LocalizedText } from '../LocalizedText';

const TextItemContainer = styled.div`
  margin: 8px;
`;

export const textReviewContentItemFragment = gql`
  fragment textReviewContentItemFragment on TextReviewContentItem {
    text
    localizedText
  }
`;

export const TextItem = (item: TextReviewContentItemFragmentFragment) => {
  return (
    <CoursewareItemContainer type={item.__typename}>
      <TextItemContainer>
        <LocalizedText item={item} />
      </TextItemContainer>
    </CoursewareItemContainer>
  );
};
