import * as React from 'react';
import styled from '@emotion/styled';
import gql from 'graphql-tag';
import { CoursewareItemContainer } from './CoursewareItemContainer';
import { AudioMessageItemFragmentFragment } from '../../generated/graphql';
import { LocalizedText } from '../LocalizedText';

const AudioWrapper = styled.div`
  width: 400px;
  min-height: 45px;
  border-radius: 45px;
  padding: 0 5px;
  background-size: contain;
  margin: 5px;
`;

const TranscriptLine = styled.div`
  color: #999;
  font-size: 12px;
  display: flex;
  padding: 5px 0;
  align-items: center;
`;

export const audioMessageItemFragment = gql`
  fragment audioMessageItemFragment on AudioMessageItem {
    url
    duration
    transcripts {
      text
      localizedText
    }
  }
`;

export const AudioMessageItem = (item: AudioMessageItemFragmentFragment) => {
  const renderTranscript = (
    transcripts: AudioMessageItemFragmentFragment['transcripts'],
  ) =>
    (transcripts || []).map((line, i) => (
      <TranscriptLine key={i}>
        💬 <LocalizedText item={line} />
      </TranscriptLine>
    ));

  return (
    <CoursewareItemContainer type={item.__typename}>
      <AudioWrapper>
        <audio css={{ outline: 'none' }} controls src={item.url}>
          Your browser does not support the
          <code>audio</code> element.
        </audio>
        {item.transcripts ? renderTranscript(item.transcripts) : null}
      </AudioWrapper>
    </CoursewareItemContainer>
  );
};
