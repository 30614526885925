import React from 'react';
import styled from '@emotion/styled';
import ReactMarkdown from 'react-markdown';

const BoldText = styled.span`
  font-weight: bold;
`;

// `ReactMarkdown` wraps everything in a <p> tag for some reason. This is to stop that <p> tag from adding
// extra margin to the page on everything by hackily setting its margin to 0
const MarkdownWrapper = styled.div`
  & > p:last-child {
    margin: 0;
  }
`;

interface MarkdownProps {
  text?: string;
}

export const Markdown = (props: MarkdownProps) => (
  <MarkdownWrapper>
    <ReactMarkdown
      source={(props.text || '').trim().replace(/\n/gi, '  \n')}
      renderers={{
        strong: BoldText,
      }}
      unwrapDisallowed
    />
  </MarkdownWrapper>
);
