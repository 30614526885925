import * as React from 'react';
import styled from '@emotion/styled';
import gql from 'graphql-tag';
import { AudioMessageItem, audioMessageItemFragment } from './AudioMessageItem';
import { CoursewareItemContainer } from './CoursewareItemContainer';
import { AudioCarouselMessageItemFragmentFragment } from '../../generated/graphql';

const CarouselWrapper = styled.div`
  padding: 20px 6px 1px;
`;
const AudioWrapper = styled.div`
  margin: 10px 0;
`;

export const audioCarouselMessageItemFragment = gql`
  fragment audioCarouselMessageItemFragment on AudioCarouselMessageItem {
    items {
      ...audioMessageItemFragment
    }
  }
  ${audioMessageItemFragment}
`;

export const AudioCarouselMessageItem = (
  item: AudioCarouselMessageItemFragmentFragment,
) => {
  return (
    <CoursewareItemContainer type={item.__typename}>
      <CarouselWrapper>
        {item.items.map((audio, i) => (
          <AudioWrapper key={i}>
            <AudioMessageItem {...audio} />
          </AudioWrapper>
        ))}
      </CarouselWrapper>
    </CoursewareItemContainer>
  );
};
