import React from 'react';
import styled from '@emotion/styled';

export const FloatingRightLabel: React.FC<any> = (props: {
  title?: string;
  extra?: string;
  color?: string;
}) => {
  const RightLabel = styled.div`
    position: absolute;
    left: 110%;
    min-width: 160px;
    opacity: 0;

    div:hover > & {
      opacity: 1;
    }
  `;

  const Title = styled.h3`
    color: rgba(0, 0, 0, 0.87);
    font-size: 1.1125rem;
    margin: 0;
  `;
  const Extra = styled.p`
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.75rem;
    font-weight: 400;
    margin: 4px 0 0 0;
  `;

  return (
    <RightLabel>
      <Title>{props.title}</Title>
      <Extra>{props.extra}</Extra>
    </RightLabel>
  );
};
