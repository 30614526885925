import * as React from 'react';
import gql from 'graphql-tag';
import styled from '@emotion/styled';
import { CardMessageItem, cardMessageItemFragment } from './CardMessageItem';
import { CoursewareItemContainer } from './CoursewareItemContainer';
import { CardCarouselMessageItemFragmentFragment } from '../../generated/graphql';

export const cardCarouselMessageItemFragment = gql`
  fragment cardCarouselMessageItemFragment on CardCarouselMessageItem {
    items {
      ...cardMessageItemFragment
    }
  }
  ${cardMessageItemFragment}
`;

const CardWrapper = styled.div`
  margin: 10px 0;
`;

const Spacer = styled.div`
  width: 100%;
  height: 10px;
`;

export const CardCarouselMessageItem = (
  item: CardCarouselMessageItemFragmentFragment,
) => {
  return (
    <CoursewareItemContainer type={item.__typename}>
      <Spacer />
      {item.items.map((card, i) => (
        <CardWrapper key={i}>
          <CardMessageItem {...card} />
        </CardWrapper>
      ))}
    </CoursewareItemContainer>
  );
};
