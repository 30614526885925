import * as React from 'react';
import gql from 'graphql-tag';
import { audioReviewContentItemFragment } from './Audio';
import { videoReviewContentItemFragment } from './Video';
import { imageReviewContentItemFragment } from './Image';
import { textReviewContentItemFragment } from './Text';
import { cardReviewContentItemFragment } from './Card';
import { cardCarouselReviewContentItemFragment } from './CardCarousel';
import { audioCarouselReviewContentItemFragment } from './AudioCarousel';
import { gifReviewContentItemFragment } from './Gif';
import { webLinkReviewContentItemFragment } from './WebLink';
import {
  CoursewareReviewSummaryContentItemFragmentFragment,
  ReviewContentItemFragmentFragment,
} from '../../generated/graphql';
import { Topic, reviewTopicFragment } from './Topic';
import { CoursewareItem } from './CoursewareItem';

export const coursewareReviewSummaryContentItemFragment = gql`
  fragment coursewareReviewSummaryContentItemFragment on CoursewareReviewSummaryContentItem {
    ...textReviewContentItemFragment
    ...gifReviewContentItemFragment
    ...webLinkReviewContentItemFragment
    ...cardReviewContentItemFragment
    ...videoReviewContentItemFragment
    ...audioReviewContentItemFragment
    ...imageReviewContentItemFragment
    ...audioCarouselReviewContentItemFragment
    ...cardCarouselReviewContentItemFragment

    ...reviewTopicFragment
  }

  ${textReviewContentItemFragment}
  ${gifReviewContentItemFragment}
  ${webLinkReviewContentItemFragment}
  ${cardReviewContentItemFragment}
  ${videoReviewContentItemFragment}
  ${audioReviewContentItemFragment}
  ${imageReviewContentItemFragment}
  ${audioCarouselReviewContentItemFragment}
  ${cardCarouselReviewContentItemFragment}

  ${reviewTopicFragment}
`;

export const CoursewareReviewSummaryContentItem = (
  item: CoursewareReviewSummaryContentItemFragmentFragment,
) => {
  if (item.__typename === 'ReviewTopic') {
    return <Topic {...item} />;
  }
  return <CoursewareItem {...(item as ReviewContentItemFragmentFragment)} />;
};
