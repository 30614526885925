import * as React from 'react';
import styled from '@emotion/styled';
import gql from 'graphql-tag';
import { CoursewareItemContainer } from './CoursewareItemContainer';
import { CardReviewContentItemFragmentFragment } from '../../generated/graphql';
import { LocalizedText } from '../LocalizedText';

const CardWrapper = styled.figure`
  width: 240px;
  border-radius: 4px;
  border: 1px solid #555;
  position: relative;
`;

const CardBodyWrapper = styled.div`
  padding: 5px;
`;

export const cardReviewContentItemFragment = gql`
  fragment cardReviewContentItemFragment on CardReviewContentItem {
    title {
      text
      localizedText
    }
    items {
      ... on CardReviewContentItemBodyText {
        text
        localizedText
      }
      ... on CardReviewContentItemBodyImage {
        url
        aspect
      }
    }
    subtitle {
      text
      localizedText
    }
    image {
      url
      aspect
    }
    audio {
      url
      duration
    }
  }
`;

interface HeaderProps {
  hasImage: boolean;
}

const HeaderWrapper = styled.div<HeaderProps>(props => {
  const baseStyles = {
    margin: 0,
    padding: '5px',
  };
  if (!props.hasImage) return baseStyles;
  return {
    position: 'absolute',
    left: 0,
    top: 0,
    color: 'white',
    width: '100%',
    height: '100%',
    zIndex: 100,
    background:
      'linear-gradient(177deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.1) 40%, rgba(0,0,0,0) 100%)',
    ...baseStyles,
  };
});

const HeaderAudio = styled.audio`
  outline: none;
  width: 100%;
  height: 30px;
`;

const H3Wrapper = styled.h3<HeaderProps>(props => {
  return props.hasImage ? { color: 'white', margin: 0 } : { margin: 0 };
});

export const CardItem = (item: CardReviewContentItemFragmentFragment) => (
  <CoursewareItemContainer type={item.__typename}>
    <CardWrapper>
      <div style={{ position: 'relative' }}>
        <HeaderWrapper hasImage={!!item.image}>
          <H3Wrapper hasImage={!!item.image}>
            <LocalizedText item={item.title} />
          </H3Wrapper>
          {item.subtitle ? <LocalizedText item={item.subtitle} /> : null}
        </HeaderWrapper>
        {item.image && (
          <img src={item.image.url} style={{ minHeight: '100px' }} />
        )}
      </div>
      {item.audio && (
        <HeaderAudio controls src={item.audio.url}>
          Your browser does not support the
          <code>audio</code> element.
        </HeaderAudio>
      )}
      <CardBodyWrapper>
        {item.items.map((bodyItem, index) => {
          if (bodyItem.__typename === 'CardReviewContentItemBodyText') {
            return (
              <p key={index}>
                <LocalizedText item={bodyItem} />
              </p>
            );
          }
          if (bodyItem.__typename === 'CardReviewContentItemBodyImage') {
            return <img key={index} src={bodyItem.url} />;
          }
          return null;
        })}
      </CardBodyWrapper>
    </CardWrapper>
  </CoursewareItemContainer>
);
