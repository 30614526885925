import * as React from 'react';
import styled from '@emotion/styled';
import gql from 'graphql-tag';
import { CoursewareItemContainer } from './CoursewareItemContainer';
import { VideoReviewContentItemFragmentFragment } from '../../generated/graphql';

const VideoWrapper = styled.figure``;

export const videoReviewContentItemFragment = gql`
  fragment videoReviewContentItemFragment on VideoReviewContentItem {
    url
    aspect
    duration
  }
`;

export const VideoItem = (item: VideoReviewContentItemFragmentFragment) => {
  return (
    <CoursewareItemContainer type={item.__typename}>
      <VideoWrapper>
        <video width="240" css={{ outline: 'none' }} controls src={item.url}>
          Your browser does not support the
          <code>video</code> element.
        </video>
      </VideoWrapper>
    </CoursewareItemContainer>
  );
};
