import React, { FC } from 'react';
import { LocalizeContext } from './LanguageToggleContextContainer';
import { Markdown } from './Markdown';

interface ILocalizedTextProps {
  item: { text: string; localizedText?: string | null };
}

export const LocalizedText: FC<ILocalizedTextProps> = ({ item }) => (
  <LocalizeContext.Consumer>
    {localize => (
      <Markdown text={(localize && item.localizedText) || item.text} />
    )}
  </LocalizeContext.Consumer>
);
