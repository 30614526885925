import React, { FunctionComponent, useState } from 'react';
import gql from 'graphql-tag';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/react-hooks';
import { fromGlobalId } from 'graphql-relay';
import flatten from 'lodash.flatten';
import { Link } from 'gatsby';
import { Box, Chip } from '@material-ui/core';

import { exists, capitalize } from '../../util';
import { Dialog, dialogFragment } from '../dialog/Dialog';
import { LeftHighlightBorderContainer } from '../LeftHighlightBorderContainer';
import {
  CoursewareReviewSummaryContentItem,
  coursewareReviewSummaryContentItemFragment,
} from '../courseware/CoursewareReviewSummaryContentItem';
import {
  LoadSummaryQuery,
  LoadConversationQuery,
  LoadSectionInfoQuery,
  SectionNavQuery,
} from '../../generated/graphql';
import { getSectionMode, setSectionMode } from '../../persistentStorage';
import { LanguageToggleContextContainer } from '../LanguageToggleContextContainer';
import { normalizeId } from '../../normalizeId';
import { GraphQLErrorDisplay } from '../GraphQLErrorDisplay';
import {
  VocabChipWithDialog,
  vocabChipWithDialogItemFragment,
} from '../courseware/VocabChipWithDialog';

type Mode = 'conversation' | 'summary';

interface CoursewareSectionPageProps {
  id: string;
}

const conversationQuery = gql`
  query LoadConversation($id: ID!) {
    node(id: $id) {
      id
      ... on CoursewareStudySection {
        dialog {
          ...dialogFragment
        }
      }
    }
  }

  ${dialogFragment}
`;

const summaryQuery = gql`
  query LoadSummary($id: ID!) {
    node(id: $id) {
      id
      ... on CoursewareStudySection {
        reviewSummaryContents {
          ...coursewareReviewSummaryContentItemFragment
        }
      }
    }
  }

  ${coursewareReviewSummaryContentItemFragment}
`;

const sectionInfoQuery = gql`
  query LoadSectionInfo($id: ID!) {
    node(id: $id) {
      id
      ... on CoursewareStudySection {
        title
        description
        sectionType
        implicitVocab
        vocab {
          id
          word
          ...vocabChipWithDialogItem
        }
        lesson {
          id
          title
          numberInCourse
          course {
            id
            title
          }
        }
      }
    }
  }
  ${vocabChipWithDialogItemFragment}
`;

const sectionNavQuery = gql`
  query SectionNav {
    courses {
      id
      lessons {
        id
        sections {
          id
          sectionType
        }
      }
    }
  }
`;

const SectionScreen = styled.div`
  width: 500px;
  border-radius: 12px;
  background: white;
`;
const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const InfoBox = styled.div`
  border: 1px solid black;
  padding: 16px;
  margin-bottom: 16px;
`;
const InfoItem = styled.div``;
const BottomNavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 16px 0;
  font-size: 14px;
`;

const ModeToggleContainer = styled.div`
  display: flex;
  border: 1px solid #eee;
  margin-bottom: 20px;
`;

interface ToggleButtonProps {
  isSelected: boolean;
}
const ToggleButton = styled.button<ToggleButtonProps>(
  ({ isSelected }) => `
  border: none;
  outline: none;
  cursor: pointer;
  padding: 5px 30px;
  flex-grow: 1;
  background: ${isSelected ? '#663399' : 'none'};
  color: ${isSelected ? 'white' : '#333'};
`,
);

const defaultMode = getSectionMode();

const CoursewareSectionpage: FunctionComponent<CoursewareSectionPageProps> = props => {
  const [mode, setMode] = useState(defaultMode);
  const id = normalizeId(props.id, 'CoursewareStudySection');

  const renderConversationContents = () => {
    const { loading, error, data } = useQuery<LoadConversationQuery>(
      conversationQuery,
      {
        variables: { id },
      },
    );
    if (error) return <GraphQLErrorDisplay error={error} />;

    if (
      !data ||
      !data.node ||
      data.node.__typename !== 'CoursewareStudySection' ||
      !data.node.dialog ||
      data.node.id !== id
    ) {
      if (loading) return <p>Loading...</p>;
      return <p>Not found :(</p>;
    }

    return (
      <LeftHighlightBorderContainer color="purple">
        <Dialog {...data.node.dialog} />
      </LeftHighlightBorderContainer>
    );
  };

  const renderSummaryContents = () => {
    const { loading, error, data } = useQuery<LoadSummaryQuery>(summaryQuery, {
      variables: { id },
    });
    if (error) return <GraphQLErrorDisplay error={error} />;

    if (
      !data ||
      !data.node ||
      data.node.__typename !== 'CoursewareStudySection' ||
      !data.node.reviewSummaryContents ||
      data.node.id !== id
    ) {
      if (loading) return <p>Loading...</p>;
      return <p>Not found :(</p>;
    }

    return (
      <>
        {data.node.reviewSummaryContents.map((item: any, i: number) => (
          <CoursewareReviewSummaryContentItem {...item} key={i} />
        ))}
      </>
    );
  };

  const renderNav = () => {
    const { loading, error, data } = useQuery<SectionNavQuery>(sectionNavQuery);
    if (error) return <GraphQLErrorDisplay error={error} />;
    if (loading) return <BottomNavContainer>Loading...</BottomNavContainer>;

    // force typescript to be sane
    const flatSections = flatten(
      flatten(
        data?.courses?.map(course =>
          course?.lessons.map(lesson => lesson.sections),
        ) || [],
      ).filter(exists),
    );

    const curSectionIndex = flatSections.findIndex(
      section => section.id === id,
    );
    const nextId =
      curSectionIndex < flatSections.length - 1 &&
      flatSections[curSectionIndex + 1].id;
    const prevId = curSectionIndex > 0 && flatSections[curSectionIndex - 1].id;

    const curSectionType = flatSections[curSectionIndex].sectionType;
    const nextOfType = flatSections
      .slice(curSectionIndex + 1)
      .find(section => section.sectionType === curSectionType);

    return (
      <BottomNavContainer>
        <Link to="/courseware">Courseware</Link>
        {prevId && (
          <Link to={`/courseware/section/${prevId}`}>Previous Page</Link>
        )}
        {nextId && <Link to={`/courseware/section/${nextId}`}>Next Page</Link>}
        {nextOfType ? (
          <Link to={`/courseware/section/${nextOfType.id}`}>
            Next {capitalize(curSectionType)}
          </Link>
        ) : null}
      </BottomNavContainer>
    );
  };

  const renderSectionInfo = () => {
    const { loading, error, data } = useQuery<LoadSectionInfoQuery>(
      sectionInfoQuery,
      { variables: { id } },
    );
    if (error) return <GraphQLErrorDisplay error={error} />;

    if (
      !data ||
      !data.node ||
      data.node.__typename !== 'CoursewareStudySection' ||
      data.node.id !== id
    ) {
      if (loading) return <InfoBox>Loading...</InfoBox>;
      return <p>Not found :(</p>;
    }
    const sectionInfo = data.node;
    const coursewareVocabWords =
      sectionInfo.vocab?.map(vocab => vocab.word) || [];

    return (
      <>
        <InfoBox>
          <InfoItem>
            <b>Course:</b>{' '}
            <Link to={`/courseware`}>{sectionInfo.lesson.course.title}</Link>
          </InfoItem>
          <InfoItem>
            <b>Lesson:</b>{' '}
            <Link to={`/courseware/lesson/${sectionInfo.lesson.id}`}>
              {sectionInfo.lesson.numberInCourse} - {sectionInfo.lesson.title}
            </Link>
          </InfoItem>
          <InfoItem>
            <b>Lesson ID:</b> {fromGlobalId(sectionInfo.lesson.id).id}
          </InfoItem>
          <InfoItem>
            <b>Section:</b> {sectionInfo.title}
          </InfoItem>
          <InfoItem>
            <b>Section ID:</b> {fromGlobalId(sectionInfo.id).id}
          </InfoItem>
          {/* TODO: put back section.subtitle after adding it to the API */}
          <InfoItem>
            <b>Section description:</b> {sectionInfo.description}
          </InfoItem>
          <InfoItem>
            <b>Vocab:</b>
            {sectionInfo.vocab?.map(vocab => (
              <Box m={0.5} display="inline-block">
                <VocabChipWithDialog item={vocab} key={vocab.id} />
              </Box>
            ))}
            {sectionInfo.implicitVocab
              ?.filter(word => !coursewareVocabWords.includes(word))
              ?.map(word => (
                <Box m={0.5} display="inline-block">
                  <Chip key={word} label={word} />
                </Box>
              ))}
          </InfoItem>
        </InfoBox>
        <h1>{sectionInfo.title}</h1>
      </>
    );
  };

  const onUpdateMode = (newMode: Mode) => {
    setSectionMode(newMode);
    setMode(newMode);
  };

  return (
    <Container>
      <SectionScreen>
        {renderNav()}
        {renderSectionInfo()}
        <LanguageToggleContextContainer>
          <ModeToggleContainer>
            <ToggleButton
              isSelected={mode === 'summary'}
              onClick={() => onUpdateMode('summary')}
            >
              Summary
            </ToggleButton>
            <ToggleButton
              isSelected={mode === 'conversation'}
              onClick={() => onUpdateMode('conversation')}
            >
              Conversation
            </ToggleButton>
          </ModeToggleContainer>
          {mode === 'summary' && renderSummaryContents()}
          {mode === 'conversation' && renderConversationContents()}
        </LanguageToggleContextContainer>
      </SectionScreen>
    </Container>
  );
};

export default CoursewareSectionpage;
